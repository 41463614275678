import { defineStore, skipHydrate } from 'pinia';
import { ref, computed, watch } from '@vue/composition-api';
import {
  ROUTES,
  useBasicInformation,
  useAuthentication,
} from '@vf/composables';

import type { ComponentInstance } from '@vf/composables';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from './featureFlags';
type SimplifiedEnrollmentStep =
  | 'ProgressiveProfileModal'
  | 'SimplifiedEnrollmentToast'
  | 'ProgressiveProfileConfirmation';

export const useSimplifiedEnrollmentStore = (instance: ComponentInstance) => {
  return defineStore('simplifiedEnrollment', () => {
    const { basicInformation } = useBasicInformation(instance);
    const { isSimplifiedEnrollmentEnabled } = useFeatureFlagsStore();
    const activeComponent = skipHydrate(ref<SimplifiedEnrollmentStep>(null));
    const { loyaltyLegacyUser, loyaltyEnrolled } = useAuthentication(instance);

    const activateToast = () =>
      loyaltyEnrolled.value &&
      (activeComponent.value = 'SimplifiedEnrollmentToast');
    const activateProgressiveProfile = () =>
      (activeComponent.value = 'ProgressiveProfileModal');
    const activateProgressiveProfileConfirmation = () =>
      (activeComponent.value = 'ProgressiveProfileConfirmation');

    const deactivate = () => (activeComponent.value = null);

    const isPartiallyEnrolled = skipHydrate(
      computed(() => {
        return (
          basicInformation.value.email &&
          !(
            basicInformation.value.phone &&
            basicInformation.value.firstName &&
            basicInformation.value.lastName &&
            basicInformation.value.postalCode &&
            basicInformation.value.birthDate &&
            basicInformation.value.preferences.interests
          )
        );
      })
    );

    const showGoBackButton = ref(false);

    if (isClient && isSimplifiedEnrollmentEnabled) {
      watch(
        () => [instance.$route.path, isPartiallyEnrolled.value],
        ([path, partiallyEnrolled]) => {
          // we exclude paths with "sign" so we prevent displaying toast on login page or sign out
          if (
            typeof path === 'string' &&
            !path.includes('sign') &&
            path.includes(ROUTES.PROFILE()) &&
            partiallyEnrolled &&
            activeComponent.value !== 'ProgressiveProfileModal' && // to prevent unexpeded closing of progressive profile modal
            loyaltyLegacyUser.value === false // to prevent showing toast for non loyalty users
          ) {
            activateToast();
          }
        },
        { immediate: true }
      );
    }

    return {
      activeComponent,
      activateToast,
      activateProgressiveProfile,
      activateProgressiveProfileConfirmation,
      deactivate,
      isPartiallyEnrolled,
      showGoBackButton,
    };
  })();
};

/* eslint-disable  @typescript-eslint/no-explicit-any */

import { States, Segment } from './cmsPropsEntities';
import { Ref } from '@vue/composition-api';

export interface CmsSitesConfigurationResponse {
  sites: CmsSite[];
}
export interface CmsSiteConfigurationResponse {
  site: CmsSite;
}
export type CmsArticlesResponse = {
  searchpages: {
    numFound: number;
    taxonomies: CmsArticleFilters[];
    pages: CmsArticle[];
  };
};

export type CmsArticleFilters = {
  id: string;
  value: string;
};

export type ComponentConfig<T = any> = {
  name: string;
  values: T;
};

export type CmsArticle = {
  id: string;
  type: string;
  name: string;
  viewtype: null;
  title: string;
  gridRow: {
    small: number;
    medium: number;
    large: number;
  };
  componentConfig: ComponentConfig[];
  teaserTitle: string;
  teaserText: string;
  teaserRichTextObject: {
    html: string;
    links: { id: string; link: CMTeaserTarget['target'] }[];
  };
  teaserTargets: CMTeaserTarget[];
  openInNewTab: boolean;
  openInNewModal: boolean;
  detailText: string;
  pictures: CMPicture[];
  authors: [];
  publicationDate: string;
  subjectTaxonomy: CmsArticleFilters[];
};

export type PageType =
  | 'category'
  | 'customizer'
  | 'homepage'
  | 'product'
  | 'search'
  | 'tag'
  | null;

export type ModalSettings = {
  type: 'page' | 'fragment' | 'lazyFragment' | null;
  resourceId?: string;
  path?: string;
  contextKey?: string;
  additionalData?: AdditionalData;
};

export type AdditionalData = {
  bundle_partnumber?: string;
  components?: unknown;
  currency?: string;
  format?: string;
  image?: string;
  palettes?: unknown;
  pdm?: string;
  priceInDecimal?: string;
  priceInString?: string;
  recipe_id?: string;
  sizesObject?: unknown;
  cssAnimationDuration?: number;
  experienceId?: string;
  listType?: string;
  showGoBackButton?: Ref<boolean>;
};

export interface CmsMenuResponse {
  menu: {
    menu: CmsMenu;
    footer: CmsFooter;
    header: CmsHeader;
  };
}

export interface CmsMenuGridColumn {
  smallSize: number;
  mediumSize: number;
  largeSize: number;
  smallPosition: number;
  mediumPosition: number;
  largePosition: number;
  smallHidden: boolean;
  mediumHidden: boolean;
  largeHidden: boolean;
  smallNoBottomMargin: boolean;
  mediumNoBottomMargin: boolean;
  largeNoBottomMargin: boolean;
  components: CmsMenuItemLevel2[];
  paddings: {
    small: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    medium: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    large: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
  };
  margins: {
    small: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    medium: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    large: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
  };
}

export interface CmsMenu {
  logo: [CmsLogo] | null;
  moreText: string;
  shopAllText: string;
  id: string;
  mobileTitle: string | null;
  dataNavigation: string | null;
  ariaLabel: string | null;
  externalId: string | null;
  children: CmsMenuItem[];
  componentConfig: ComponentConfig[];
  title: string | null;
}

export type CmsMenuItemType =
  | 'link'
  | 'placeholder'
  | 'image'
  | 'product'
  | 'externalUrl'
  | 'internalUrl';

export interface CmsMenuItem {
  title: string;
  id: string;
  name: string;
  externalId: string | null;
  mobileTitle: string | null;
  dataNavigation: string | null;
  ariaLabel: string | null;
  cssClass: string | null;
  style: string | null;
  overrideAltText: string | null;
  overrideLinkTracking: string | null;
  linkTitle: string | null;
  overrideLinkText: string | null;
  openInNewModal: boolean | null;
  openInNewTab: boolean | null;
  placeHolder: boolean | null;
  exposeCatChildren: boolean | null;
  expandMobile: boolean | null;
  componentStyles: Record<string, string>[];
  accordionOpenByDefault: { ['accordion-open-by-default-mobile']: boolean };
  target: CMComponent[];
}

export interface CmsMenuItemLevel1 extends CmsMenuItem {
  gridColumn: {
    columns: CmsMenuGridColumn[];
  };
}

export interface CmsMenuItemLevel2 extends CmsMenuItem {
  children: {
    commerceChildren: CMCommerceRef[];
    contentChildren: CmsMenuItem[];
  };
}
export type CmsMenuItemLevel =
  | CmsMenuItemLevel1
  | CmsMenuItemLevel2
  | CmsMenuItem;

export interface CmsLogo {
  id: string;
  type: string;
  name: string;
  title: string;
  alt: string;
  copyright: string;
  caption: string | null;
  forcedAbsoluteUrl: boolean;
  data: {
    uri: string;
  };
  uriTemplate: string;
}

export interface CmsSite {
  id: string;
  name: string;
  locale: string;
  domain: string;
  crops: CMPictureCrop[];
  commerceConfig: {
    catalogId: string;
    catalogName: string;
    currency: string;
    locale: string;
    storeId: string;
    storeName: string;
    brand: string;
  };
  previewSliderMetadata: CmsSitePreviewSliderMetadata;
  root: {
    id: string;
    type: 'CMChannel';
    name: string;
    segment: string;
  };
  commonConfig: {
    [key: string]: string | CMCreditCard[];
  };
  commonConfigExpanded: CmsSiteCommonConfigExpanded[];
}

export interface CmsSitePreviewSliderMetadata {
  cm_responsiveDevices: {
    mobile_landscape: {
      width: number;
      height: number;
      order: number;
    };
    tablet_portrait: {
      width: number;
      height: number;
      order: number;
    };
    tablet_landscape: {
      width: number;
      height: number;
      order: number;
    };
    mobile_portrait: {
      width: number;
      height: number;
      order: number;
    };
  };
  cm_preferredWidth: number;
}

export interface CmsSiteCommonConfigExpanded {
  path: string;
  links: CMComponent[];
}

export type ImageSizeName = 'small' | 'medium' | 'large';

export interface VFConfigComponent {
  id: string;
  type: 'VFConfigComponent';
  name: string;
  localSettings: any;
  localSettingsExpanded?: any;
}
export interface VFFlexibleComponent {
  id: string;
  type: 'VfFlexibleComponent';
  name: string;
  viewType: string;
  [key: string]: any;
}

export type CMTrackingData = {
  enableTracking: boolean | null;
  tracking_position: string | null;
  tracking_creative: string | null;
};

export type CMComponent = (
  | CMPlaceholder
  | CMTeaser
  | CMPicture
  | CMVideo
  | CMExternalChannel
  | CMCollection
  | CMExternalLink
  | VFConfigComponent
  | VFFlexibleComponent
  | CMDownload
) &
  Partial<CMTrackingData>;

export interface CmsPageResponse {
  pagecontent: CmsPageContent;
}

export interface CmsFragmentResponse {
  data: {
    content: {
      content: CMResourceBundle;
    };
  };
}

export interface CMHTML {
  id: string;
  type: 'CMHTML';
  name: string;
  viewtype: string;
  title: string;
  componentConfig: ComponentConfig[];
  teaserTitle: string;
  teaserText: string;
  teaserTargets: [];
  openInNewTab: boolean | null;
  openInNewModal: boolean | null;
  scrollToComponent: boolean | null;
  description: string;
  detailText: string;
}
export interface CmsFragmentFolderResponse {
  data: {
    content: {
      fragmentByFolder: CMHTML;
    };
  };
}

export type CMSRichTextObject = {
  html: string;
  links: { id: string; link: CMTeaserTarget['target'] }[];
};

export interface CmsCategoryPageResponse {
  data: {
    content: {
      commercepage: CmsCategoryPageContent;
    };
  };
}

export interface CmsProductPageResponse {
  data: {
    content: {
      productpage: CmsProductPageContent;
    };
  };
}

export interface CmsCategoryPageContent {
  externalId: string;
  grid: {
    cssClassName: string;
    rows: CmsRow[];
  };
  locales: Record<string, unknown>[];
  commerceRef: CMCommerceRef;
  commercePath: CMCommerceRef[];
}

export interface CmsProductPageContent {
  externalId: string;
  grid: {
    cssClassName: string;
    rows: CmsRow[];
  };
  locales: Record<string, unknown>[];
  commerceRef: CMCommerceRef;
  commercePath: CMCommerceRef[];
}

export interface CmsPageContent {
  grid: {
    cssClassName: string;
    rows: CmsRow[];
  };
  locales: Record<string, unknown>[];
  localizations: Record<string, unknown>;
  externalId?: string;
}

export interface CmsRow {
  placements: CmsPlacement[];
}

export interface CmsPlacement {
  name: string;
  colspan: {
    small: number;
    medium: number;
    large: number;
    noMargin?: boolean;
  };
  col: number;
  width: number;
  pageItems: CMComponent[];
}

export interface CmsMonetateExperienceComponentDataProps {
  experienceId: string;
  forcedVariantLabel: string | null;
  _states: States[] | null;
  _segments: Segment[] | null;
  baseline: Record<string, unknown>;
  defaultVariant: Record<string, unknown>;
  variants: {
    label: string;
    id: string;
    content: Record<string, unknown>;
  }[];
}

export type CmsMonetateExperienceComponentData = {
  data: {
    component: string;
    _cms_id: string;
    props: CmsMonetateExperienceComponentDataProps;
  };
  errors: string[];
};

export type CmsMonetateComponent =
  | CmsP13ExperienceComponent
  | CmsP13NSegmentationComponent;

export interface ExperienceVariant {
  variantId: string;
  name: string;
  target: CMComponent;
}

export interface CmsP13ExperienceComponent {
  experienceId: string;
  baseline: CMComponent;
  variants: ExperienceVariant[];
}

export interface CmsP13NSegmentationComponent {
  experienceId: string;
  baseline: CMComponent;
  variants: ExperienceVariant[];
}

export interface CmsGridColumn {
  id: string;
  _injectedProps?: Record<string, unknown>;
  gridColumn: {
    fullWidth: boolean;
    backgroundColor: string;
    columns: CmsGridColumnColumn[];
    contentPosition: {
      small: string;
      medium: string;
      large: string;
    };
  };
}

export interface CmsGridOverImage {
  id: string;
  pictures: CMPicture[];
  videos: CMVideo[];
  responsiveMedia: ResponsiveMedia | null;
  gridColumn: {
    fullWidth: boolean;
    columns: CmsGridColumnColumn[];
    contentPosition: {
      small: string;
      medium: string;
      large: string;
    };
    background: {
      mediaLayout: string;
      position: string;
      size: string;
      repeat: string;
    };
  };
}

export interface CmsGridColumnColumn {
  components: CMComponent[];
  smallSize: number;
  mediumSize: number;
  largeSize: number;
  smallPosition: number;
  mediumPosition: number;
  largePosition: number;
  smallHidden: boolean;
  mediumHidden: boolean;
  largeHidden: boolean;
  smallNoBottomMargin: boolean;
  mediumNoBottomMargin: boolean;
  largeNoBottomMargin: boolean;
  contentPosition: {
    small: string;
    medium: string;
    large: string;
  };
  sticky: boolean;
  paddings: {
    small: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    medium: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    large: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
  };
  margins: {
    small: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    medium: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
    large: {
      custom: string;
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
  };
  verticalAlign:
    | 'center'
    | 'start'
    | 'end'
    | 'self-start'
    | 'self-end'
    | 'flex-start'
    | 'flex-end';
}

export interface CMPlaceholder {
  id: string;
  type: 'CMPlaceholder';
  name: string;
  viewtype: string;
  title: string;
  _injectedProps?: Record<string, unknown>;
  componentConfig: ComponentConfig[];
  localSettings: {
    icon: string;
    mobile?: boolean;
    dynamicFields?: {
      showLanguagePreference?: boolean;
      icon?: string;
      actionButton?: string;
      mobile?: boolean;
      position?: number;
      mobileWidth?: string;
      desktopWidth?: string;
      tabletWidth?: string;
      component?: ComponentConfig[];
      categories?: ComponentConfig[];
    };
  };
  localSettingsExpanded: { path: string; links: any[] }[];
  resourceBundle: {
    common: Record<string, string>;
    binaryContent: [];
  };
}

export interface CMTeaser {
  type: 'CMTeaser';
  id: string;
  name: string;
  title: string;
  _injectedProps?: Record<string, unknown>;
  detailRichTextObject: {
    html: string;
    links: CMComponent[];
  };
  teaserRichTextObject: {
    html: string;
    links: { id: string; link: CMTeaserTarget['target'] }[];
  };
  teaserSubtitle: string;
  teaserTargets: CMTeaserTarget[];
  teaserText: string | null;
  teaserTitle: string;
  useRichText: boolean;
  openInNewTab: boolean;
  openInNewModal: boolean;
  scrollToComponent: boolean;
  viewtype: string | null;
  pictures: CMPicture[];
  videos: CMVideo[];
  localSettings: {
    icon: string;
    urlParams?: string;
    mobile?: boolean;
    dynamicFields?: {
      icon?: string;
      actionButton?: string;
      mobile?: boolean;
      formLocation?: string;
      enabled?: boolean;
      type?: string;
    };
  };
  responsiveMedia: ResponsiveMedia | null;
  localSettingsExpanded: { path: string; links: CMComponent[] }[];
  resourceBundle: {
    common: Record<string, unknown>;
    binaryContent: [];
  };
  urlParams: string;
  alt?: string;
  campaignId?: string;
  componentConfig: ComponentConfig[];
}

export interface CMContentHero {
  type: 'VfContentHero';
  id: string;
  name: string;
  viewtype: string;
  title: string;
  teaserTitle: string;
  teaserSubtitle: string;
  teaserTargets?: CMTeaserTargetContentLink[];
  pictures: CMPicture[];
  videos: CMVideo[];
  responsiveMedia: ResponsiveMedia | null;
}

export interface CMContentBodyText {
  type: 'VfContentBodyText';
  id: string;
  name: string;
  viewtype: string;
  title: string;
  teaserTitle: string;
  teaserRichTextObject: {
    html: string;
    links: { id: string; link: CMTeaserTarget['target'] }[];
  };
  teaserTargets?: CMTeaserTargetContentLink[];
}

export interface CMContentHorizontalSimple {
  type: 'VfContentHorizontalSimple';
  id: string;
  name: string;
  viewtype: string;
  title: string;
  teaserTitle: string;
  teaserRichTextObject: {
    html: string;
    links: { id: string; link: CMTeaserTarget['target'] }[];
  };
  pictures: CMPicture[];
  videos: CMVideo[];
  teaserTargets?: CMTeaserTargetContentLink[];
}

export interface CMContentTextBlock {
  type: 'VfContentTextBlock';
  id: string;
  name: string;
  viewtype: string;
  title: string;
  teaserTitle: string;
  teaserSubtitle: string;
  teaserRichTextObject: {
    html: string;
    links: { id: string; link: CMTeaserTarget['target'] }[];
  };
  teaserTargets?: CMTeaserTargetContentLink[];
}

export interface CMExternalChannel {
  type: 'CMExternalChannel';
  id: string;
  name: string;
  viewtype: string;
  title: string;
  teaserTargets: CMTeaserTarget[];
  teasableItemsCount?: number;
  teasableItems?: Record<string, unknown>[];
  localSettings?: any;
}

export interface CMImageBlockWithOverlay {
  type: 'VfEasyImageBlock';
  id: string;
  name: string;
  viewtype: string;
  title: string;
  teaserTitle: string;
  teaserText: string;
  teaserTargets?: CMTeaserTargetContentLink[];
  pictures: CMPicture[];
  videos: CMVideo[];
  responsiveMedia: ResponsiveMedia | null;
}

export interface CMContentList {
  type: 'VfEasyList';
  id: string;
  name: string;
  viewtype: string;
  title: string;
  teaserTitle: string;
  teaserText: string;
  teaserTargets?: CMTeaserTargetContentLink[];
  pictures: CMPicture[];
  videos: CMVideo[];
  responsiveMedia: ResponsiveMedia | null;
  componentStyles: CMContentListComponentStyles;
}

export interface CMContentListComponentStyles {
  linkUnderline: boolean;
  sortOptions: 'sort-alphabetical' | 'sort-manual';
  teaserTitleColor: string;
  bottomMargin: string;
  columnCount: string;
  teaserTitleStyle: string;
  rowBackgroundColor: string;
  textColor: string;
}

export interface CMExternalLink {
  id: string;
  name: string;
  type: 'CMExternalLink';
  url: string;
  viewtype: string | null;
  teaserTitle: string;
  openInNewTab: boolean | null;
  callToActionCustomText: string;
  teaserText: string | null;
  localSettings?: any;
}

export interface CMDownload {
  id: string;
  type: 'CMDownload';
  name: string;
  viewtype: string | null;
  title: string;
  segment: string;
  teaserTitle: string;
  teaserText: string | null;
  openInNewTab: boolean | null;
  openInNewModal: boolean | null;
  filename: string;
  localSettings?: any;
  data: {
    uri: string;
  };
}

export interface CMResourceBundle {
  id: string;
  type: 'CMResourceBundle';
  name: string;
  localizations: Record<string, unknown>;
}

export interface CMTeaserTarget {
  callToActionEnabled: boolean;
  callToActionText: string;
  target: {
    id: string;
    name: string;
    segment: string;
    title: string;
    type: string;
    teaserTargets: any[];
    commerceRef: CMCommerceRef | null;
    url: string;
    urlParams: string;
  };
}

export interface CMTeaserTargetContentLink extends CMTeaserTarget {
  urlParams: string | null;
  linkBehavior:
    | 'openInNewTab'
    | 'openInNewModal'
    | 'scrollToComponent'
    | ''
    | null;
  callToActionStyle:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'textLink'
    | 'primaryHoverAlt'
    | ''
    | null;
  callToActionIcon: 'chevron_right' | 'customize' | 'pin' | 'play' | '' | null;
}

export interface CMCommerceRef {
  id: string;
  name: string;
  internalLink: string;
  siteId: string;
  type: 'category' | 'product';
  title: string;
  link: string;
}

export enum CMPictureCropType {
  LANDSCAPE_RATIO_8x3 = 'landscape_ratio8x3_',
  RATIO_1x1 = 'ratio-1x1',
  RATIO_16x15 = 'ratio-16x15',
  RATIO_4x3 = 'ratio-4x3',
  RATIO_4x1 = 'ratio-4x1',
  RATIO_72x7 = 'ratio-72x7',
  RATIO_180x97 = 'ratio-180x97',
  RATIO_45x16 = 'ratio-45x16',
  RATIO_60x11 = 'ratio-60x11',
  RATIO_256x35 = 'ratio-256x35',
  RATIO_64x9 = 'ratio-64x9',
  RATIO_128x75 = 'ratio-128x75',
  RATIO_128x37 = 'ratio-128x37',
  RATIO_9x12 = 'ratio-9x12',
  RATIO_16x9 = 'ratio-16x9',
  RATIO_2x1 = 'ratio-2x1',
}

export interface CMPictureCrop {
  aspectRatio: {
    height: number;
    width: number;
  };
  name: CMPictureCropType;
  minWidth: number;
  minHeight: number;
  sizes: CmsCropSize[];
}

export interface CmsCropSize {
  height: number;
  width: number;
}

export interface CMPicture {
  type: 'CMPicture';
  id: string;
  name: string;
  title: string;
  teaserTitle?: string;
  alt: string;
  useOriginalImage: boolean;
  viewtype: string | null;
  copyright: string;
  caption: string | null;
  forcedAbsoluteUrl: boolean;
  dataUrl: string;
  data: {
    uri: string;
  };
  uriTemplate: string;
  seoTitle: string;
  seoDescription: string;
  seoDate: string;
  localSettings?: any;
  width: number;
  height: number;
  componentConfig: ComponentConfig[];
  vfCloudinaryAsset?: {
    cloudName: string;
    publicId: string;
    version: string;
  };
}

export interface CMChannel {
  id: string;
  type: 'CMChannel';
  name: string;
  viewtype: string;
  title: string;
  segment: 'homepage';
  teaserTitle: string;
  teaserText: string;
  openInNewTab: boolean;
  openInNewModal: boolean;
  scrollToComponent: boolean;
  urlSegment: string;
}

export interface ResponsiveMedia {
  imageSizing: {
    config: string;
    crop: {
      small: CMPictureCropType;
      medium: CMPictureCropType;
      large: CMPictureCropType;
    };
  };
  medium?: CMPicture[] | CMVideo[];
  large?: CMPicture[] | CMVideo[];
}

export interface MediaSeoAttributes {
  title: string;
  description: string;
  date: string;
}

export interface CMVideoTrack {
  data: { uri: string; contentType: string } | null;
  label: string;
  kind: string;
  srclang: string;
  default: boolean;
}

export interface CMVideo {
  type: 'CMVideo';
  id: string;
  name: string;
  creationDate: string;
  title: string;
  teaserTitle?: string;
  extDisplayedDate: string | null;
  videoTracks: CMVideoTrack[] | null;
  viewtype: string | null;
  validFrom: string | null;
  validTo: string | null;
  alt: string;
  copyright: string;
  caption: string | null;
  data: {
    uri: string;
    type: string;
  };
  dataUrl: string;
  responsiveMedia: ResponsiveMedia | null;
  seoTitle: string;
  seoDescription: string;
  seoDate: string;
  localSettings?: any;
  pictures: CMPicture[];
  width?: number;
  height?: number;
  componentConfig: ComponentConfig[];
}

export interface CMGenericCollection<ItemType> {
  id: string;
  type: 'CMCollection';
  name: string;
  viewtype: string;
  teaserTitle: string;
  teaserText: string;
  teasableItemsCount: number;
  teasableItems: ItemType[];
  localSettings?: any;
}

export interface CMCollection {
  id: string;
  type: 'CMCollection';
  name: string;
  viewtype: string;
  teaserTitle: string;
  teaserText: string;
  teasableItemsCount: number;
  teasableItems: CMTeaser[];
  localSettings?: any;
  componentConfig: ComponentConfig[];
}

export interface CMContentRow {
  id: string;
  type: 'VfEasyContentRow';
  name: string;
  viewtype: string;
  teaserTitle: string;
  teaserSubtitle: string;
  teasableItemsCount: number;
  teasableItems: CMTeaser[];
  teaserTargets: CMTeaserTargetContentLink[];
  localSettings: any;
  componentConfig: ComponentConfig[];
  componentStyles: Record<string, string>;
}

/**
 * Footer
 */

export type CmsFooter = (
  | CmsFooterColumns
  | CmsSocialMedia
  | CmsSiteLogo
  | CmsBottomBar
  | CmsCta
  | CmsStoreLocator
  | CmsSubscribe
  | CmsBackToTop
)[];
export interface CmsFooterColumns {
  id: string;
  type: string;
  name: string;
  viewtype: 'footer-columns-set';
  teaserTitle: string | null;
  teaserText: string | null;
  teasableItemsCount: number;
  teasableItems: CMCollection[];
}

export interface CmsSocialMedia {
  id: string;
  type: string;
  name: string;
  viewtype: 'footer-follow-vf';
  teaserTitle: string | null;
  teaserText: string | null;
  teasableItemsCount: number;
  teasableItems: CMExternalLink[];
}

export interface CmsSiteLogo {
  id: string;
  type: string;
  name: string;
  viewtype: 'site-logo';
  teaserTitle: string;
  teaserText: string | null;
  teaserTargets: CMTeaserTarget[];
  pictures: CMPicture[];
  videos: CMVideo[];
  localSettings: {
    small: string;
    medium: string;
    large: string;
  };
}

export interface CmsBottomBar {
  id: string;
  type: string;
  name: string;
  viewtype: 'footer-bottomBar';
  teaserTitle: string;
  teaserText: string | null;
  teaserTargets: CMTeaserTarget[];
  pictures: CMPicture[];
  videos: CMVideo[];
  openInNewModal: boolean;
  openInNewTab: boolean;
  urlParams: string;
}

export interface CmsCta {
  id: string;
  type: string;
  name: string;
  viewtype: 'footer-cta';
  teaserTitle: string;
  teaserText: string | null;
  teaserTargets: CMTeaserTarget[];
  pictures: CMPicture[];
  videos: CMVideo[];
}

export interface CmsStoreLocator {
  id: string;
  type: string;
  name: string;
  viewtype: 'footer-store-locator';
  teaserTitle: string;
  teaserText: string | null;
  teaserTargets: CMTeaserTarget[];
  pictures: CMPicture[];
  videos: CMVideo[];
  urlParams: string;
}

export interface CmsSubscribe {
  id: string;
  type: string;
  name: string;
  viewtype: 'footer-subscribe';
  teaserTitle: string;
  teaserRichText: string | null;
  teaserTargets: CMTeaserTarget[];
  pictures: CMPicture[];
  videos: CMVideo[];
  localSettings: {
    dynamicFields: {
      errorMessage: string;
      successMessage: string;
      emailError: string;
      requiredError: string;
    };
  };
}

export interface CmsBackToTop {
  id: string;
  type: string;
  name: string;
  viewtype: 'footer-backToTop';
  teaserTitle: string;
  teaserText: string | null;
  teaserTargets: CMTeaserTarget[];
  pictures: CMPicture[];
  videos: CMVideo[];
}

/**
 * Header
 */
export type CmsHeader = (
  | CmsUtilNavigation
  | CmsUtilNavigationMobile
  | CmsMegaNav
  | CmsPromoBar
  | CmsSearchBar
  | CmsHeaderMiniCart
  | CmsHeaderFavoritesTooltip
)[];

export interface CmsUtilNavigation {
  id: string;
  type: string;
  name: string;
  viewtype: 'utility-navigation';
  teaserTitle: string | null;
  teaserText: string | null;
  teasableItemsCount: number;
  teasableItems: CMTeaser[];
}

export interface CmsUtilNavigationMobile {
  id: string;
  type: string;
  name: string;
  viewtype: 'utility-navigation-small';
  teaserTitle: string | null;
  teaserText: string | null;
  teasableItemsCount: number;
  teasableItems: CMTeaser[];
  resourceBundle: {
    common: Record<string, string>;
  };
}

export interface CmsPromoBar {
  id: string;
  type: string;
  name: string;
  viewtype: 'promo-bar';
  teaserTitle: string | null;
  teaserText: string | null;
  teasableItemsCount: number;
  teasableItems: (CMTeaser | CMCollection)[];
  localSettings: {
    dynamicFields: {
      enableOverlayArrows: number;
      interval: number;
      enablePauseOnHover: number;
      enableSwiping: number;
      showClose: boolean;
      closeLabel: string;
    };
    callToActionDisabled: boolean;
    callToActionCustomText: string;
    teaserSettings: {
      renderLinkToDetailPage: boolean;
    };
  };
}
export interface CmsMegaNav {
  id: string;
  type: 'CMCollection';
  name: string;
  viewtype: 'meganav';
  teaserTitle: string;
  teaserText: string | null;
  teasableItemsCount: number;
  teasableItems: (CMCollection | CMTeaser | CMPlaceholder)[];
}

export interface CmsSearchBar {
  id: string;
  type: string;
  name: string;
  viewtype: 'header-search';
  title: string;
  localSettings: {
    dynamicFields: {
      searchPlaceholder: string;
      searchAriaLabel: string;
      requiredMessage: string;
      searchAllLabel: string;
      seeMoreLabel: string;
      resultsFoundAria: string;
      closeSuggestionsLabel: string;
      featuredLabel: string;
      resultsToShow: number;
      suggestionsToShow: number;
      visualSearchShowTooltip: boolean;
      visualSearchTooltipCloseButtonText: string;
      visualSearchTooltipButtonText: string;
      visualSearchTooltipHtml: string;
    };
  };
}
export interface CmsHeaderMiniCart {
  id: string;
  type: string;
  name: string;
  viewtype: 'header-mini-cart';
  title: string;
  localSettings: {
    dynamicFields: {
      icon: string;
    };
  };
  resourceBundle: {
    common: Record<string, string>;
  };
}

export interface CMCreditCard {
  enabled: boolean;
  type: string;
}

export interface CmsHeaderFavoritesTooltip {
  viewtype: 'header-favorites-tooltip';
  link: string;
  resourceBundle: {
    common: Record<string, string>;
  };
}

export interface DiscountNotificationTranslations {
  employee: {
    message: string;
    exceededMessage: string;
  };
  athlete: {
    message: string;
    exceededMessage: string;
  };
  ipa: {
    message: string;
    exceededMessage: string;
  };
  wranx: {
    message: string;
    exceededMessage: string;
  };
  seasonalExceededMessage: string;
}
